import React from "react"
import Seo from "../../../components/seo"
import Layout from "../../../Layout"
import Breadcrumbs from "../../../components/Breadcrumbs"
import Image1 from "./images/carrots_898.jpg"
import Image2 from "./images/image1.webp"
import Image3 from "./images/image3.jpg"
import Image4 from "./images/image4.jpg"
import Image5 from "./images/image5.jpg"
import Image6 from "./images/image7.jpg"
import FarmImage1 from "./images/farm1.jpg"
import FarmImage2 from "./images/farm2.jpg"

const images = [Image1, Image2, Image3, Image4, Image5, Image6]
const healthBenefits = [
  {
    text: "Good for your eyes",
    details: "Carrots have vitamin A, which helps you see better.",
  },
  {
    text: "Boosts your immunity",
    details: "They have vitamin C to fight sickness.",
  },
  {
    text: "Makes your skin glow",
    details: "Carrots protect your skin and keep it healthy.",
  },
  {
    text: "Healthy heart",
    details: "They lower cholesterol and improve blood pressure.",
  },
  {
    text: "Helps with digestion",
    details: "Carrots are full of fiber to prevent constipation.",
  },
  {
    text: "Manages blood sugar",
    details: "Carrots are great for people watching their sugar levels.",
  },
  {
    text: "Keeps your teeth strong",
    details: "Eating raw carrots cleans your teeth naturally.",
  },
  {
    text: "Protects from cancer",
    details: "Carrots have antioxidants that fight harmful cells.",
  },
]

const CarrotPage = () => (
  <Layout>
    <Seo title="Carrot" />
    <Breadcrumbs title="Carrot" />
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-10 col-lg-8">
          <style>
            {`
              .fixed-size {
                width: 120px;
                height: 120px;
                object-fit: cover;
                margin: 3px;
              }
              .narrow-paragraph {
                max-width: 700px;
                margin: 0 auto;
              }
              .custom-table {
                width: 100%;
                margin-top: 20px;
                border-collapse: collapse;
              }
              .custom-table th, .custom-table td {
                border: 1px solid #ddd;
                padding: 8px;
              }
              .custom-table th {
                background-color: #f2f2f2;
                text-align: left;
              }
              .custom-table .green-header {
                background-color: #d4edda;
              }
              .margin-bottom {
                margin-bottom: 50px; /* Adjust this value to increase the gap */
              }
              .farm-description {
                display: flex;
                align-items: center;
                margin-bottom: 15px;
              }
              .farm-description img {
                margin-right: 15px;
                width: 120px; /* Set width to match the first image */
                height: 120px; /* Set height to match the first image */
                object-fit: cover; /* Ensure the image covers the area */
              }
            `}
          </style>
          <div className="row">
            <div className="col-8">
              <div className="row">
                {images.slice(0, 3).map((image, index) => (
                  <div className="col-4" key={index}>
                    <img
                      src={image}
                      alt="Carrot field"
                      className="img-thumbnail fixed-size"
                    />
                  </div>
                ))}
              </div>
              <div className="row mt-2">
                {images.slice(3).map((image, index) => (
                  <div className="col-4" key={index}>
                    <img
                      src={image}
                      alt="Carrot field"
                      className="img-thumbnail fixed-size"
                    />
                  </div>
                ))}
              </div>
              <div className="row mt-3 narrow-paragraph">
                <div className="col-12">
                  <p>
                    Organic carrots are a vibrant, nutritious root vegetable
                    known for their sweet taste and health benefits. They’re
                    rich in vitamins A, C, and K, as well as potassium and
                    fiber. Vitamin A, derived from beta-carotene, supports
                    vision and immune health. Carrots also provide antioxidants
                    that protect against chronic diseases, aid digestion with
                    their high fiber content, and promote heart health by
                    helping to manage blood pressure. Additionally, the
                    nutrients and antioxidants in carrots are great for skin
                    health.
                  </p>
                  <p>
                    Choosing organic means no synthetic pesticides, sustainable
                    farming practices, and many believe a better taste. Carrots
                    come in various colors like purple, red, yellow, and white.
                    Fun fact: The longest carrot ever recorded was over 20 feet
                    long! For storage, keep them in the refrigerator in a
                    perforated plastic bag or a damp paper towel.
                  </p>
                </div>
              </div>
              <h3>
                <strong>Nutrition Table</strong>
              </h3>
              <table className="custom-table margin-bottom">
                <thead>
                  <tr>
                    <th className="green-header">Nutrient</th>
                    <th className="green-header">Carrot, medium, raw</th>
                    <th className="green-header">Carrot Slices, cooked</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Weight</td>
                    <td>61 g / 2.2 oz</td>
                    <td>78 g / 2.8 oz</td>
                  </tr>
                  <tr>
                    <td>Calories</td>
                    <td>25 kcal</td>
                    <td>25 kcal</td>
                  </tr>
                  <tr>
                    <td>Total Fat</td>
                    <td>0 g</td>
                    <td>0 g</td>
                  </tr>
                  <tr>
                    <td>Saturated Fat</td>
                    <td>0 g</td>
                    <td>0 g</td>
                  </tr>
                  <tr>
                    <td>Trans Fat</td>
                    <td>0 g</td>
                    <td>0 g</td>
                  </tr>
                  <tr>
                    <td>Cholesterol</td>
                    <td>0 mg</td>
                    <td>0 mg</td>
                  </tr>
                  <tr>
                    <td>Sodium</td>
                    <td>40 mg</td>
                    <td>45 mg</td>
                  </tr>
                  <tr>
                    <td>Total Carbohydrates</td>
                    <td>6 g</td>
                    <td>6 g</td>
                  </tr>
                  <tr>
                    <td>Dietary Fiber</td>
                    <td>2 g</td>
                    <td>2 g</td>
                  </tr>
                  <tr>
                    <td>Protein</td>
                    <td>1 g</td>
                    <td>1 g</td>
                  </tr>
                  <tr>
                    <td>Sugars</td>
                    <td>3 g</td>
                    <td>3 g</td>
                  </tr>
                  <tr>
                    <td>Vitamin A</td>
                    <td>200 %</td>
                    <td>270 %</td>
                  </tr>
                  <tr>
                    <td>Vitamin C</td>
                    <td>6 %</td>
                    <td>4 %</td>
                  </tr>
                  <tr>
                    <td>Calcium</td>
                    <td>2 %</td>
                    <td>2 %</td>
                  </tr>
                  <tr>
                    <td>Iron</td>
                    <td>2 %</td>
                    <td>2 %</td>
                  </tr>
                </tbody>
              </table>
              <h3>
                <strong>Our Organic Farms</strong>
              </h3>
              <div className="farm-description">
                <img
                  src={FarmImage1}
                  alt="Organic Farm 1"
                  className="img-thumbnail"
                />
                <p>Farm at Nuwara Eliya Control Union Certified</p>
              </div>
              <div className="farm-description">
                <img
                  src={FarmImage2}
                  alt="Organic Farm 2"
                  className="img-thumbnail"
                />
                <p>Farm at Chilaw PGS Certified</p>
              </div>
            </div>
            <div className="col-4 ml-auto">
              <h2>Health Benefits of Carrots</h2>
              <ul style={{ listStyleType: "none", paddingLeft: "0" }}>
                {healthBenefits.map((benefit, index) => (
                  <li key={index}>
                    <span style={{ color: "green" }}>✅</span>
                    <span style={{ color: "black" }}>
                      <strong>{benefit.text}:</strong> {benefit.details}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default CarrotPage
